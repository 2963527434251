@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Display:wght@300;400;500;700&display=swap");

html,
body,
#root,
.app {
  font-family: -apple-system, BlinkMacSystemFont, "Noto Sans Display", "Ubuntu",
    sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  background: #f2f1f6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* scrollbar  */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* track  */
::-webkit-scrollbar-track {
  background: #fff;
}

/* handle  */
::-webkit-scrollbar-thumb {
  background: #e1e0e0;
}
/* handle  */
::-webkit-scrollbar-thumb:hover {
  background: #999;
}

/* handle on hover  */
::-webkit-scrollbar-track:hover {
  background: #e1e0e0;
}

@media only screen and (max-width: 1000px) {
  /* scrollbar  */
  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
}
